import React from "react";
import { Link } from "gatsby";

import fbIcon from "../../images/footer/facebook.png";
import instaIcon from "../../images/footer/instagram.png";
import linkedIcon from "../../images/footer/linkedin.png";
import twIcon from "../../images/footer/twitter.png";
import news from "../../images/footer/news.png";

const footer = () => {
  return (
    <div className="footer text-white">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-3 mb-md-0">
            <h5 className="">Quick Links</h5>
            <ul className="list-unstyled small">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/news">News</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-3 mb-md-0">
            <h5 className="">About Us</h5>
            <ul className="list-unstyled small">
              <li>
                <Link to="/about-us">Our Team</Link>
              </li>
              <li>
                <Link to="/company-awards">Awards</Link>
              </li>
              {/* <li>
                <Link to="/peernetwork">PeerNetworks</Link>
              </li> */}
              <li>
                <Link to="/technology/testimonials">Testimonials</Link>
              </li>
              <li>
                <Link to="/charities">Charities</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-3 mb-md-0">
            <h5 className="">Candidates</h5>
            <ul className="list-unstyled small">
              <li>
                <Link to="/jobs">Search for Jobs</Link>
              </li>
              <li>
                <Link to="/benefits">Benefits</Link>
              </li>
              <li>
                <Link to="/referral-program">Referral Program</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 mb-md-0">
            <h5 className="">Employers</h5>
            <ul className="list-unstyled small">
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/recruiting-services">Recruiting Services</Link>
              </li>
              <li>
                <Link to="/payroll-services">Payroll Services</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4 mb-3 mb-md-0">
            <h5 className="">Address</h5>
            <ul className="list-unstyled small">
              <li>
                <Link to="#">
                  10303 E. Dry Creek Road Suite 400, Englewood, CO 80112
                </Link>
              </li>
            </ul>
            <h5 className="">Call Us</h5>
            <ul className="list-unstyled small">
              <li>
                <a href="tel:303-867-1111">303-867-1111</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="social-icons text-center">
            <a
              href="http://www.linkedin.com/company/peersource"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={linkedIcon} alt="linkedin" />
            </a>
            <a
              href="https://www.facebook.com/PeerSource/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={fbIcon} alt="facebook" />
            </a>
            <a
              href="https://twitter.com/mypeersource"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={twIcon} alt="twitter" />
            </a>
            <a
              href="https://www.instagram.com/mypeersource/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={instaIcon} alt="instagram" />
            </a>
            <a href="/news">
              <img src={news} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <span>
            © {new Date().getFullYear()} PeerSource. All Rights Reserved.
            Designed and Powered by {``}
            <a
              href="https://webriq.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WebriQ
            </a>
            .
          </span>
        </div>
      </div>
    </div>
  );
};

export default footer;
